<template>
    <div class="col-md-12 frm">
        <div class="center-card">
            <div class="center-card-box">
                <a href="javascript:void(0)" @click="goBack" class="bckbtn"><i class="fas fa-arrow-left"></i></a><span>{{pageName}}</span>
                <div class="col-md-12" v-if="!iframeurl">
                    <h1><skeleton-loader-vue type="string" width="24%" height="20px" style="top: 20px" /></h1>
                    <skeleton-loader-vue type="string" width="100%" height="100px" style="top: 60px" />
                </div>
                <div v-else class="d-flex align-items-start plr15">
                    <iframe 
                    class="embed-responsive-item" :src="iframeurl" style="width: 100%; border: none;min-height: 100px;max-height: 750px;height: 100vh;"
                    allowfullscreen/>
                </div>            
            </div>
        </div>            
    </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';
export default {
    components: {'skeleton-loader-vue':VueSkeletonLoader  },
    props: ['iframeurl','pageName'],
     data() {
        return {            
            is_load_skeleton : true,
        }
    },
    methods: {
        goBack(){
            let allPages = true;
            this.$emit("displaypages", allPages);            
        }
    }
};
</script>
<style scoped>
a.bckbtn {
    font-size: 24px;
    padding: 20px;
}
.frm span {
    color: var(--theme-font-primary);
}
</style>