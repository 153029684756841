<template>
    <div class="login-main">
        <nav-bar></nav-bar>
        <section class="box-flex">
            <div class="container review-main text-capitalize position-relative faq-main box-flex__wrapper" v-if="is_load_skeleton">
                <div class="row">
                    <div class="col-md-12 p-0">
                        <div class="center-card">
                            <div class="center-card-box">
                                <h1><skeleton-loader-vue type="string" width="100%" height="20px" style="top: 20px" /></h1>
                                <h1><skeleton-loader-vue type="string" width="100%" height="20px" style="top: 20px" /></h1>
                                <h1><skeleton-loader-vue type="string" width="100%" height="20px" style="top: 20px" /></h1>              
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div v-else class="container review-main text-capitalize position-relative faq-main box-flex__wrapper">                
                <div class="row" v-if="!loadIframe">
                    <div class="col-md-12 p-0">
                        <div class="center-card">
                            <div class="center-card-box | p-3 border-bottom top-sticky">
                                <h4 class="text-start">{{ $t('About Us') }}</h4>
                            </div>
                            <div class="center-card-box | p-3">
                                <div class="d-flex justify-content-between align-items-center mb-4" >
                                    <ul class="support-list w-100">
                                        <li v-for="page in pages" :key="page.name" class="support-list__item">
                                            <a class="support-list__link justify-content-between" href="javascript:void(0)" @click="loadIframeAction(page.url,page.name)">{{page.name}} <i class="fas fa-chevron-right"></i></a>
                                        </li>
                                    </ul>
                                </div>  
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="row" v-if=loadIframe>
                    <staticiframe :iframeurl="iframeLink" :pageName="staticPageName"  v-on:displaypages="setStaticPages"></staticiframe>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import VueSkeletonLoader from 'skeleton-loader-vue';
import Footer from '../components/Footer.vue';
import staticiframe from '../components/StaticIframe.vue';

export default {
    components: { NavBar,Footer,'skeleton-loader-vue':VueSkeletonLoader,staticiframe},
    data() {
        return {
            pages: [],            
            is_load_skeleton : true,
            loadIframe:false,
            iframeLink:'',
            staticPageName:''
        }
    },
    mounted() {
        setTimeout(()=>{
            this.setStaticPages();
            let isStaticPage  = JSON.parse(localStorage.getItem("isStaticPage"));                        
            this.loadIframe = false;
            if(isStaticPage && Object.keys(isStaticPage).length > 0 ){
                this.loadIframeAction(isStaticPage.url, isStaticPage.name);
                this.loadIframe = true;
            }
        },1200);
    },
    methods:{
        setStaticPages() {
            if (this.$auth.setting) {
                if (this.$auth.setting.static_pages && this.$auth.setting.static_pages.length > 0) {
                    let pgs = [];
                    this.$auth.setting.static_pages.forEach(ele => {
                        if(ele.display_in.customer == '1') {
                            pgs.push({'name':ele.page_name,'url':ele.url})
                        }
                    });
                    this.is_load_skeleton = false;
                    this.loadIframe = false;
                    this.pages = pgs;                    
                }else{
                    this.is_load_skeleton = false;
                }
            }
        },

        loadIframeAction(url, name){            
            this.loadIframe = true
            this.iframeLink = url;
            this.staticPageName = name;
            localStorage.removeItem('isStaticPage');
        },        
    }
}
</script>
<style scoped>
.rightarrw{
    cursor: pointer;
    font-weight: bold;

}
</style>